import React, { useState, useEffect, useMemo } from 'react';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';
import { sampleSize, uniqBy } from 'lodash';
import { Container } from '@mui/material';
import { Attraction } from 'swingby-shared/dist/interfaces';
import useTempInfo from '../hooks/useTempInfo';
import useDeviceDetect from '../hooks/useDeviceDetect';
import { graphqlClient } from '../lib/graphql';
import { GET_ATTRACTIONS } from './attractions/[parkUid].page';
import { IResult } from '../types';
import Header from './main/components/Header';
import { MainBannerSkeleton, MenusSkeleton } from './main/components/Skeleton';
import styled from '@emotion/styled';
import SSRSafeSuspense from '../components/Suspense/SSRSafeSuspense';
import { ParkUid } from 'swingby-shared';

const MainBanner = dynamic(() => import('./main/components/MainBanner'), { suspense: true });
const Menus = dynamic(() => import('./main/components/Menus'), { suspense: true });
const Attractions = dynamic(() => import('./main/components/Attractions'), { ssr: false });
const NoticeWelcomeCouponSnackBar = dynamic(() => import('./main/components/NoticeWelcomeCouponSnackBar'), {
  ssr: false,
});
const CheckWelcomeCouponModal = dynamic(() => import('./main/components/Modal/CheckWelcomeCouponModal'), {
  ssr: false,
});
const MaintenanceModal = dynamic(() => import('./main/components/Modal/MaintenanceModal'), { ssr: false });
const AppFooter = dynamic(() => import('../components/Footer/app'), { ssr: false });
const BottomNav = dynamic(() => import('../components/GNB/BottomNav'), { ssr: false });
const UpdateInactiveUserModal = dynamic(() => import('./main/components/Modal/UpdateInactiveUserModal'));

export default function Home({ attractions: data }: InferGetStaticPropsType<typeof getStaticProps>) {
  const { isMobile, isApp } = useDeviceDetect();
  const { removeTempInfo } = useTempInfo();

  const [showNoticeWelcomeCouponSnackBar, setShowNoticeWelcomeCouponSnackBar] = useState<boolean>(false);

  const attractions = useMemo(() => sampleSize(data, 4), [data]);

  useEffect(() => {
    const isLoggedIn = globalThis?.localStorage.getItem('token');
    if (!isLoggedIn) setShowNoticeWelcomeCouponSnackBar(true);
  }, []);

  useEffect(() => {
    removeTempInfo();
  }, []);

  return (
    <>
      <Header />
      <Container disableGutters sx={{ pb: 7, flex: '1 1 0' }}>
        <MainBannerContainer>
          <SSRSafeSuspense fallback={<MainBannerSkeleton />}>
            <MainBanner />
          </SSRSafeSuspense>
        </MainBannerContainer>

        <MenuContainer>
          <SSRSafeSuspense fallback={<MenusSkeleton />}>
            <Menus />
          </SSRSafeSuspense>
        </MenuContainer>

        <Attractions attractions={attractions} />

        {isMobile() && <AppFooter />}
      </Container>
      {!isApp() && <BottomNav />}

      <MaintenanceModal />
      <CheckWelcomeCouponModal />
      <UpdateInactiveUserModal />
      <NoticeWelcomeCouponSnackBar
        isModalOpen={showNoticeWelcomeCouponSnackBar}
        setModalOpen={setShowNoticeWelcomeCouponSnackBar}
      />
    </>
  );
}

export const getStaticProps: GetStaticProps<{ attractions: Attraction[] }> = async () => {
  const { result } = await graphqlClient.request<IResult<Attraction[]>>(GET_ATTRACTIONS, {
    filter: { isOperating: true },
    parkUid: ParkUid.Suwon,
  });

  return {
    props: {
      attractions: sampleSize(uniqBy(result, 'imageUrl'), 4),
    },
  };
};

const MainBannerContainer = styled.div`
  max-height: 580px;
  aspect-ratio: 1.11;
`;

const MenuContainer = styled.div`
  max-height: 170px;
  aspect-ratio: 3.8;
`;
